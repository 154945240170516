body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* Set up a dark background */
body {
  background-color: #1a1a1a;
  color: #fff;
}

/* Keyframes for spinning animation */
@keyframes spin {
  from {
    transform: rotate(360deg);
  }
  to {
    transform: rotate(0);
  }
}

/* CSS for the profile picture frame */
.profile-picture {
  width: 150px;
  height: 150px;
  border: 3px solid #fff; /* You can customize the color */
  border-radius: 50%;
  overflow: hidden;
  margin: 0 auto; /* Center the frame */
  position: relative; /* To position the image inside the frame */
}

.profile-picture img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
  border-radius: 50%; /* To ensure the image stays within the circle */
  position: absolute;
  top: 0;
  left: 0;
}

/* Apply spinning animation to ::after pseudo-element */
.hero h1::after {
  content: "";
  display: inline-block;
  width: 40px;
  height: 40px;
  background-image: url(/static/media/basketball.75448fe3.png); /* Replace with the path to your basketball icon */
  background-size: cover;
  margin-left: 5px;
  animation: spin 2s linear infinite; /* Adjust the duration and timing function as needed */
}

/* Style the header */
.hero {
  text-align: center;
  padding: 100px 0;
  background-color: #0f0f0f;
}

.hero h1 {
  font-size: 4rem;
  margin-bottom: 20px;
  letter-spacing: 4px;
}

.hero p {
  font-size: 1.5rem;
}

/* CSS for the About Me section */
.about-me {
  padding: 80px 0;
}

.about-me h2 {
  font-size: 2.5rem;
  margin-bottom: 30px;
  text-align: center; /* Center-align the heading */
}

.about-me p {
  font-size: 1rem;
  line-height: 1.8;
  text-align: justify; /* Justify the text */
  max-width: 800px; /* Limit the width of the paragraphs for readability */
  margin: 0 auto; /* Center-align the paragraphs */
}

/* CSS for Education Section */
.education {
  background-color: #0f0f0f;
  padding: 80px 0;
  text-align: center;
}

.education h2 {
  font-size: 2rem;
  color: #333;
  margin-bottom: 30px;
  color: #fff;
}

.education-item {
  margin-bottom: 40px;
}

.education-item h3 {
  font-size: 1.5rem;
  margin-bottom: 10px;
}

.education-item p {
  font-size: 1.1rem;
  color: #fff;
  margin-bottom: 5px;
}


/* CSS for the project slots and grids */
.project-grid {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  grid-gap: 20px;
  gap: 20px;
  overflow: hidden;
  margin-top: 20px; 
}

.project-slot {
  flex-grow: 0;
  flex-shrink: 0;
  flex-basis: calc(50% - 20px); /* Adjust width and margins as needed */
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  text-align: center;
  overflow: auto;
}

.project-slot img {
  max-width: 50%;
  height: 50%;
  border-radius: 5px;
  margin-bottom: 10px;
}

.double img {
  max-width: 45%;
  height: 50%;
  padding-left: 5px;
  padding-right: 5px;
  border-radius: 5px;
  margin-bottom: 10px;
}

.project-slot h3 {
  font-size: 1.5rem;
  margin-bottom: 10px;
}

.project-slot p {
  font-size: 1rem;
  margin: 15px;
  justify-content: center;
  align-items: flex-start;
}

.project-slot p {
  font-size: 1rem;
  margin-bottom: 15px;
  text-align: justify; 
}

.project-slot a {
  text-decoration: none;
  color: #333;
  font-size: 1rem;
}

.project-slot a i {
  margin-right: 5px;
}

/* CSS for Dimmed Project */
.dimmed {
  opacity: 0.6;
}

.typing-effect {
  overflow: hidden;
  white-space: nowrap;
  margin-bottom: 10px;
}

.typing-effect::after {
  content: '';
  display: inline-block;
  animation: typing-dots 1s infinite;
}

@keyframes typing-dots {
  0%, 20% {
    content: '';
  }
  20%, 40% {
    content: '.';
  }
  40%, 60% {
    content: '..';
  }
  60%, 100% {
    content: '...';
  }
  
}

/* Style the Contact Me section */
.contact {
  padding: 80px 0;
}

.contact h2 {
  font-size: 2.5rem;
  margin-bottom: 30px;
}

.contact p {
  font-size: 1.2rem;
  line-height: 1.8;
}

/* Style the footer */
.footer {
  text-align: center;
  padding: 40px 0;
}

@keyframes blink {
  0%, 100% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
}

.hero p {
  font-family: 'Courier New', monospace;
}

.about-me {
  background-color: #1a1a1a;
}

.projects h2 {
  font-family: 'Courier New', monospace;
}

.projects {
  background-color: #1a1a1a;
  padding: 60px 0;
}

.contact {
  background-color: #000;
  padding: 60px 0;
}

.footer {
  background-color: #1a1a1a;
}

.footer img {
  width: 40px;
  height: 40px;
  margin: 5px;
  filter: invert(100%);
}

/* Media Query for smaller devices */
@media (max-width: 768px) {
  .hero h1 {
    font-size: 3rem;
  }

  .hero p {
    font-size: 1.2rem;
  }

  .about-me h2 {
    font-size: 2rem;
  }

  .about-me p {
    font-size: 1.1rem;
  }

  .projects h2 {
    font-size: 2rem;
  }

  .contact h2 {
    font-size: 2rem;
  }

  .contact p {
    font-size: 1.1rem;
  }
}

/* CSS for the contact links */
.contact-links {
  display: flex;
  justify-content: center; /* Align icons horizontally at the center */
}

.contact-links a {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 50px; /* Adjust the width as needed */
  height: 50px; /* Adjust the height as needed */
  border-radius: 50%;
  margin: 10px; /* Adjust the margin as needed */
  background-color: #333; /* Add background color to the icons */
  color: #fff; /* Icon color */
  font-size: 24px; /* Adjust the icon size as needed */
  text-decoration: none;
  transition: background-color 0.3s ease;
}

.contact-links a:hover {
  background-color: #555; /* Adjust the hover background color */
}

/* CSS for the navigation bar */
.navbar {
  background-color: #1a1a1a;
  position: fixed;
  top: 0%;
  left: 0;
  height: 7%;
  width: 100%;
  z-index: 999;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background-color 0.3s ease;
}

.navbar ul {
  list-style: none;
  margin: 0;
  padding: 10px;
  display: flex;
  align-items: center;
}

.navbar li {
  margin-right: 20px;
}

.navbar li:last-child {
  margin-right: 0;
}

.navbar a {
  text-decoration: none;
  color: #fff;
  font-size: 18px;
  padding: 15px 20px;
  transition: color 0.3s ease;
}

.navbar a:hover {
  color: grey;
}

/* Home section takes up a quarter of the navbar */
.navbar li:nth-child(1) {
  margin-right: auto;
}

/* Highlight the active link on scroll */
.navbar a.active {
  color: grey;
}

.resume-button {
  text-decoration: none;
  color: #fff;
  border: 2px solid #fff;
  border-radius: 5px;
  transition: background-color 0.3s ease;
}

.resume-button:hover {
  background-color: #fff;
  color: #1a1a1a;
}

/* CSS for the Blog section */
.blog {
  background-color: #0f0f0f;
  padding: 80px 0;
  text-align: center;
  
}

.blog h2 {
  font-size: 2rem;
  color: #fff;
  margin-bottom: 30px;
}

.blog p {
  font-size: 1.1rem;
  color: #fff;
}

.blog a {
  display: inline-block;
  margin-top: 20px;
  padding: 10px 30px;
  background-color:#333;
  color: #fff;
  text-decoration: none;
  border-radius: 5px;
  font-size: 1rem;
  transition: background-color 0.3s ease;
}

.blog a:hover {
  background-color:grey;
}


